export const getSizeValue = (value: number | string): string => {
    if (isUndefined(value) || isNull(value)) {
        return
    }

    if (isNaN(+value)) {
        return String(value)
    } else {
        return value + 'px'
    }
}
